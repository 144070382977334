export default {
    title: 'Change Makers',
    location: '/people/change-makers',
    tabs: [
        {
            name: 'By Country',
            link: `/people/change-makers`
        },
        {
            name: 'Trend',
            link: `/people/change-makers/:market`
        }
    ]
}